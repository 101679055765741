<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-file-alt" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.clipping_pendings }}
              </h2>
              <span>Recortes Aguardando Tratativas</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-info`" size="45">
              <i class="fas fa-file-alt" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.process }}
              </h2>
              <span>Processos Cadastrados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
              <i class="fas fa-file-alt" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.process_clipping }}
              </h2>
              <span>Recortes Processados (Total)</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-success`" size="45">
              <i class="fas fa-file" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.process_clipping_now }}
              </h2>
              <span>Recortes Processados Hoje</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-primary`" size="45">
              <feather-icon size="21" :icon="'UsersIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.users }}
              </h2>
              <span>Usuários Cadastrados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <!--<div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-success`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_finalizados }}
              </h2>
              <span>Pedidos Finalizados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_cancelados }}
              </h2>
              <span>Pedidos Cancelados Manualmente</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_erro }}
              </h2>
              <span>Pedidos com Erro</span>
            </div>
          </b-card-body>
        </b-card>
      </div> -->
    </div>
    <div class="row" v-if="user.access_nivel == 1">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            Processos por Usuário
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Usuário</th>
                  <th scope="col">Quantidade de Processos</th>
                  <th scope="col">Horas Trabalhadas</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in dashboard.highlights.dashboard_users" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ user.name }}</td>
                  <td>{{ user.highlight.quantidade_processos }}</td>
                  <td>{{ user.highlight.tempo }}</td>
                  <td><button class="btn btn-crud info" @click="viewProcess(user)"><i class="fas fa-eye"></i></button></td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
        
      </div>
    </div>
  
    <!-- Modal detalhe processos -->
    <div class="modal fade" id="modalUsuarioCenter" tabindex="-1" role="dialog" aria-labelledby="modalUsuarioCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalUsuarioLongTitle">Processos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Processo</th>
                  <th scope="col">Tempo Gasto</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(process, index) in process_by_user" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ process.number }}</td>
                  <td>{{ process.total_time }}</td>
                </tr>  
                <tr v-if="process_by_user.length <= 0">
                  <td colspan="3" align="center">Nenhum processo vinculado a este usuário</td>
                </tr>              
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      user: {
        access_nivel: 2
      },
      process_by_user: {},
      dashboard: {
        highlights: {},
      },
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getDashboard() {
      const self = this;
      const api = self.$store.state.api + "dashboard";

      axios
        .post(api)
        .then((response) => {
          self.dashboard = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    viewProcess(obj) {
      const self = this;
      const api = self.$store.state.api + "process-by-user";

      axios
        .post(api, obj)
        .then((response) => {
          self.process_by_user = response.data;
          setTimeout(() => {
            $('#modalUsuarioCenter').modal('show')
          }, 1000);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;
    self.getDashboard();

    setTimeout(() => {
      self.user = self.$store.state.user;
    }, 1200);
  },
  created() { },
};
</script>

<style></style>
